<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4" v-if="user">
      <va-card-title style="font-size: 1.5rem;" v-if="id === 'add'">New User</va-card-title>
      <va-card-title style="font-size: 1.5rem;" v-else>{{ user.first_name }} {{ user.last_name }}</va-card-title>
      <va-card-title style="font-size: 1rem; margin-top: -1rem; color: #707070;" v-if="id !== 'add'">{{ user.username }} {{ user.username !== user.email ? '(' + user.email + ')' : ''}}</va-card-title>
      <va-card-content>
        <div class="table-wrapper">
          <form @submit.prevent="onsubmit">
            <va-input
              class="mb-3"
              v-model="user.email"
              type="email"
              label="Email"
              :error="!!emailErrors.length"
              :error-messages="emailErrors"
              v-if="id === 'add'"
            />

            <va-input
              class="mb-3"
              v-model="user.username"
              type="username"
              label="Username"
              :error="!!usernameErrors.length"
              :error-messages="usernameErrors"
              v-if="id === 'add'"
            />

            <va-input
              class="mb-3"
              v-model="user.first_name"
              type="text"
              label="First Name"
              :error="!!firstNameErrors.length"
              :error-messages="firstNameErrors"
            />

            <va-input
              class="mb-3"
              v-model="user.last_name"
              type="text"
              label="Last Name"
              :error="!!lastNameErrors.length"
              :error-messages="lastNameErrors"
            />

            <va-select
              class="mb-3"
              label="Access Level"
              v-model="user.access"
              :options="accessOptions"
            />
          </form>
        </div>
      </va-card-content>
      <va-card-content>
        <div class="table-wrapper">
          <form @submit.prevent="onsubmit">
            <va-input
              class="mb-3"
              v-model="user.password"
              type="password"
              label="Password"
              :error="!!passwordErrors.length"
              :error-messages="passwordErrors"
              v-if="id === 'add'"
            />

            <va-input
              class="mb-3"
              v-model="user.repassword"
              type="password"
              label="Re-Password"
              :error="!!passwordErrors.length"
              :error-messages="passwordErrors"
              v-if="id === 'add'"
            />

            <div class="d-flex justify--center mt-3">
              <va-button @click="save" class="my-0">Save</va-button>
            </div>
          </form>
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      if (this.id !== 'add') {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Load a user
        const user = await axios.get(`${process.env.VUE_APP_API_URL}/api/users/${this.id}`, { headers })
        this.user = user.data.user
      } else {
        this.user = {
          access: 'User',
          email: '',
          first_name: '',
          last_name: '',
          organization_id: '',
          uid: ''
        }
      }
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/admin/users/${this.id}` } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      user: null,
      accessOptions: ['Administrator', 'Manager', 'User'],
      passwordErrors: [],
      emailErrors: [],
      usernameErrors: [],
      firstNameErrors: [],
      lastNameErrors: [],
      id: this.$route.params.id
    }
  },
  methods: {
    save: async function () {
      this.loader = this.$loading.show()
      try {
        // Handle errors
        this.firstNameErrors = []
        this.lastNameErrors = []
        let error = false

        if (!this.user.email || this.user.email.length <= 0) {
          this.emailErrors.push('Enter valid Email')
          error = true
        }
        if (!this.user.username || this.user.username.length <= 0) {
          this.usernameErrors.push('Enter valid Username')
          error = true
        }
        if (this.id === 'add' && (!this.user.password || this.user.password.length <= 0)) {
          this.passwordErrors.push('Enter valid Password')
          error = true
        }
        if (this.id === 'add' && this.user.password && this.user.repassword && this.user.password !== this.user.repassword) {
          this.passwordErrors.push('Password and Re-password are not same')
          error = true
        }
        if (!this.user.first_name || this.user.first_name.length <= 0) {
          this.firstNameErrors.push('Enter valid First Name')
          error = true
        }
        if (!this.user.last_name || this.user.last_name.length <= 0) {
          this.lastNameErrors.push('Enter valid Last Name')
          error = true
        }

        // Check errors first
        if (!error) {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          if (this.id === 'add') {
            const payload = {
              email: this.user.email,
              password: this.user.password,
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              access: this.user.access
            }
            // Add new user
            await axios.post(`${process.env.VUE_APP_API_URL}/api/users`, payload, { headers })
            this.$router.push({ name: 'users' })
          } else {
            const payload = {
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              access: this.user.access
            }
            // Update user
            const user = await axios.put(`${process.env.VUE_APP_API_URL}/api/users/${this.id}`, payload, { headers })
            this.user = user.data.user

            // Show success message
            this.$vaToast.init({
              message: 'User saved successfully!',
              iconClass: 'fa-star-o',
              position: 'bottom-right',
              duration: 6000,
              fullWidth: false,
              color: 'primary',
            })
          }
        } else {
          // Show error message
          this.$vaToast.init({
            message: 'Please check the errors.',
            iconClass: 'fa-star-o',
            position: 'bottom-right',
            duration: 6000,
            fullWidth: false,
            color: 'danger',
          })
        }
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/admin/users/${this.id}` } })
      }
      this.loader.hide()
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }
</style>
